<template>
  <div class="login-container">
    <div class="bgc">
      <div class="center">
        <div class="box">
          <div class="login-center">
            <div class="login">
              <img src="../../assets/登录页切图/logo.png" alt="" />
            </div>
            <div class="logins">
              <div class="account">
                <el-form
                  ref="ruleForm"
                  :label-position="labelPosition"
                  :model="ruleForm"
                  :rules="userRules"
                  onsubmit="return false;"
                >
                  <el-form-item label="账号：">
                    <el-input
                      placeholder="请输入您的账号"
                      v-model="ruleForm.username"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="account">
                <el-form
                  :label-position="labelPosition"
                  :model="ruleForm"
                  :rules="userRules"
                  ref="loginFormRef"
                  onsubmit="return false;"
                >
                  <el-form-item label="密码：">
                    <el-input
                      placeholder="请输入您的密码"
                      v-model="ruleForm.password"
                      type="password"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <div class="btn" @click="login()">
            <img src="../../assets/登录页切图/button@2x.png" alt="" />
          </div>
          <h6 class="iptext">{{ $store.state.messageIp }}不在访问范围内</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/login";
import qs from "qs";
// 正式 账号:cxcw 密码：cxcw

export default {
  name: "login",
  components: {},
  props: {},
  data() {
    return {
      labelPosition: "right",
      ruleForm: {
        username: "",
        password: "",
      },
      userRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: blur },
          { min: 3, max: 10, message: "长度在3到10个字符", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: blur },
          { min: 6, max: 15, message: "长度在6到15个字符", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  watch: {},
  created() {
    console.log(this.$store.state.messageIp);
    // this.login();
    var a = this;
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (e && e.keyCode == 13) {
        //回车键的键值为13
        a.login();
      }
    };
  },
  mounted() {},
  methods: {
    login() {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) return;
        this.$store.dispatch("login", qs.stringify(this.ruleForm));
      });
    },
  },
};
</script>

<style scoped lang="less">
.bgc {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1920px;
  height: 1080px;
  background: url("../../assets/登录页切图/bgc@2x.png") no-repeat;
  background-size: contain;
}
.center {
  position: relative;
  align-items: center;
  background-position: center;
  width: 1574px;
  height: 853px;
  background: url("../../assets/登录页切图/2-bgc@2x.png") no-repeat;
  background-size: cover;
  box-shadow: 0px 10px 20px 0px rgba(2, 2, 2, 0.27),
    0px -10px 20px 0px rgba(2, 2, 2, 0.27);
  border-radius: 72px;
  .box {
    position: absolute;
    top: 125px;
    left: 157px;
    width: 402px;
    // height: 440px;
    height: 480px;
    .login-center {
      .login {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 148px;
        height: 99px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .logins {
        position: absolute;
        top: 127px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        width: 100%;
        height: 200px;
      }
    }
    .btn {
      position: absolute;
      bottom: 75px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 402px;
        height: 58px;
      }
    }
  }
}
/deep/.el-form {
  width: 402px;
  height: 59px;
}
/deep/.el-input {
  width: 320px;
}
/deep/.el-input__inner {
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-top-width: 0px;
  padding-top: 7px;
  width: 320px;
  margin-left: -15px;
}
/deep/.el-form-item__label {
  padding: 0 0px 0 0;
}
// /deep/.el-form-item{
//   margin-bottom: 11px;
//   z-index: 9999;
// }
.account {
  margin-top: 30px;
}
/deep/.el-form-item__label {
  font-size: 18px;
  font-family: Source Han Serif CN;
  font-weight: bold;
  color: #4d5d45;
}
/deep/.el-form-item {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: -10px;
}
.iptext {
  position: absolute;
  bottom: 0;
  font-size: 12px;
  color: red;
  font-weight: 400;
  width: 100%;
  text-align: center;
}
</style>
